<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row mb-2 no-gutters">
		<template v-if="!!Object.keys(address).length">
			<b-col
				lg="12"
				xl="12"
				class="px-3 pt-3 pb-2">
				<p class="mb-0">
					<b>{{ address.first_name }} {{ address.last_name }}</b>
				</p>
				<p class="mb-0">
					{{ address.address }}
				</p>
				<p class="mb-0">
					{{ address.address2 }}
				</p>
				<p class="mb-0">
					{{ address.address3 }}
				</p>
				<p class="mb-0">
					{{ address.address4 }}
				</p>
				<p class="mb-0">
					{{ address.city }}, {{ address.region.name }}, {{ address.postcode }}
				</p>
				<p class="mb-0">
					{{ translate(address.country.code.toLowerCase()) }}
				</p>
				<div
					v-if="Object.keys(shippingMethodInfo).length && shippingMethodInfo.name.length"
					class="mt-1 mb-0">
					<img
						v-if="shippingMethodInfo.image"
						:src="require(`@/assets/images/common/shipping/carriers/${shippingMethodInfo.image}`)"
						class="my-auto border"
						style="width: 45px; height: 30px;">
					<p class="text-muted d-inline">
						<span
							v-if="shippingMethodInfo.name"
							class="ml-1">
							{{ translate(shippingMethodInfo.name) }}
						</span>
						<small
							v-if="deliveryDays.length"
							class="font-italic">
							<br>{{ translate('estimated_date') }}{{ translate(deliveryDays) }}
						</small>
					</p>
					<br>
					<small
						v-if="shippingNotice.length"
						class="font-italic"
						v-html="translate(shippingNotice)" />
				</div>
			</b-col>
		</template>
		<div
			v-else
			class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="h2">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	SHIPPING_METHOD_CODES as shippingMethods,
} from '@/settings/Shipping';
import {
	Addresses as AddressesMessages, Countries as CountriesMessages, Purchase as PurchaseMessages, Validations as ValidationMessages,
} from '@/translations';
import Country from '@/util/Country';
import Addresses from '../../mixins/Addresses';
import CommonMix from '../../mixins/Common';
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'ShippingOverview',
	messages: [AddressesMessages, PurchaseMessages, ValidationMessages, CountriesMessages],
	mixins: [Addresses, CommonMix, WindowSizes],
	props: {
		addressId: {
			type: String,
			default: '',
		},
		shippingMethodName: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			stateData: new Country(),
			shippingMethods,
			checked: false,
			addressType: 'shipping',
		};
	},
	computed: {
		shippingMethodInfo() {
			try {
				const shippingMethod = { name: this.shippingMethodName, ...this.shippingMethods[this.shippingMethodName] };
				return shippingMethod;
			} catch (error) {
				return {};
			}
		},
		deliveryDays() {
			try {
				const deliveryDays = this.shippingMethodInfo.delivery_days;
				return deliveryDays[this.initialCountryCode] ? deliveryDays[this.initialCountryCode] : [];
			} catch (error) {
				return [];
			}
		},
		shippingNotice() {
			try {
				const shippingNotice = this.shippingMethodInfo.shipping_notice;
				return shippingNotice[this.initialCountryCode] ? shippingNotice[this.initialCountryCode] : shippingNotice.default;
			} catch (error) {
				return [];
			}
		},
		address() {
			const foundAddress = this.addressData.find((address) => +address.id === +this.addressId);
			return foundAddress ? foundAddress.attributes : {};
		},
	},
	watch: {
		addressId() {
			this.refreshAddressBook();
		},
	},
};
</script>
